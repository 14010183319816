import type { Ref } from 'vue';
import { computed } from 'vue';
import classNames from 'classnames';
import type {
  ProgressVariant,
  ProgressSize,
  ProgressLabelPosition,
} from '../types';

const barColorClasses: Record<ProgressVariant, string> = {
  default: 'bg-primary-100',
  blue: 'bg-primary-100',
  dark: 'bg-secondary-100',
  green: 'bg-green-spring-400',
  red: 'bg-red-100',
  yellow: 'bg-orange-mango-100',
  indigo: 'bg-indigo-100',
  purple: 'bg-purple-100',
};

const outsideTextColorClasses: Record<ProgressVariant, string> = {
  default: '',
  blue: 'text-blue-700',
  dark: 'dark:text-white',
  green: 'text-green-700',
  red: 'text-red-700',
  yellow: 'text-orange-mango-700',
  indigo: 'text-indigo-700',
  purple: 'text-purple-700',
};

const progressSizeClasses: Record<ProgressSize, string> = {
  sm: 'min-h-1.5 text-xs leading-none',
  md: 'min-h-2.5 text-xs leading-none',
  lg: 'min-h-4 text-sm leading-none',
  xl: 'min-h-6 text-base leading-tight',
};

export type UseProgressClassesProps = {
  color: Ref<ProgressVariant>;
  size: Ref<ProgressSize>;
  labelPosition: Ref<ProgressLabelPosition>;
};

export function useProgressClasses(props: UseProgressClassesProps): {
  innerClasses: Ref<string>;
  outerClasses: Ref<string>;
  outsideLabelClasses: Ref<string>;
  insideLabelClasses: Ref<string>;
} {
  const bindClasses = computed(() => {
    return classNames(
      barColorClasses[props.color.value],
      progressSizeClasses[props.size.value],
    );
  });
  const outerClasses = computed(() => {
    return classNames(progressSizeClasses[props.size.value]);
  });
  const outsideLabelClasses = computed(() => {
    return classNames(outsideTextColorClasses[props.color.value]);
  });
  const insideLabelClasses = computed(() => {
    return 'absolute left-0 top-0 text-base font-simplerRegular h-full flex items-center';
  });

  return {
    innerClasses: bindClasses,
    outerClasses,
    outsideLabelClasses,
    insideLabelClasses,
  };
}
