<template>
  <div class="w-full">
    <template v-if="label || (labelProgress && labelPosition === 'outside')">
      <div class="flex justify-between mb-1">
        <span class="text-base font-medium" :class="outsideLabelClasses">
          {{ label }}
        </span>
        <span
          v-if="labelProgress && labelPosition === 'outside'"
          class="text-sm font-medium"
          :class="outsideLabelClasses">
          {{ progress }}%
        </span>
      </div>
    </template>
    <div
      class="relative w-full bg-transparent rounded-4 dark:bg-secondary-700"
      :class="outerClasses">
      <div
        class="rounded-4 font-medium text-blue-100 text-center p-0.5 min-h-5"
        :class="innerClasses"
        :style="{ width: progress + '%' }">
        <slot v-if="hasSlot('default')"></slot>
      </div>
      <template v-if="labelProgress && labelPosition === 'inside'">
        <div :class="insideLabelClasses">{{ progress }}%</div>
      </template>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { toRefs, useSlots } from 'vue';
import type { PropType } from 'vue';
import { useProgressClasses } from './composables/useProgressClasses';
import type {
  ProgressLabelPosition,
  ProgressSize,
  ProgressVariant,
} from './types';

const props = defineProps({
  color: {
    type: String as PropType<ProgressVariant>,
    default: 'default',
  },
  label: {
    type: String,
    default: '',
  },
  labelPosition: {
    type: String as PropType<ProgressLabelPosition>,
    default: 'none',
  },
  labelProgress: {
    type: Boolean,
    default: false,
  },
  progress: {
    type: Number,
    default: 0,
  },
  size: {
    type: String as PropType<ProgressSize>,
    default: 'md',
  },
});

const { innerClasses, outerClasses, outsideLabelClasses, insideLabelClasses } =
  useProgressClasses(toRefs(props));
const slots = useSlots();
const hasSlot = name => !!slots[name];
</script>
